<template>
  <div class="bg-white shadow rounded-lg">
    <CandidateWizardRegistration />
  </div>
</template>

<script>
import CandidateWizardRegistration from '@components/CandidateWizard/CandidateWizardRegistration'

export default {
  components: {
    CandidateWizardRegistration
  }
}
</script>

<template>
  <div>
    <div v-if="magicLinkSent">
      <BaseCard class="text-center">
        <h1 class="text-3xl">
          Looks like you already have an account!
          Check your inbox for a link to log back in.
        </h1>
        <Mailbox class="w-56 text-center mx-auto my-6"/>
        <div v-if="formResponses.email">
          <p class="text-lg">
            We’ve emailed a link to <span class="font-semibold text-xl">{{ formResponses.email }}</span>
          </p>
        </div>
      </BaseCard>
    </div>
    <div
      v-else
      class="md:flex"
    >
      <div class="md:w-2/3 p-6">
        <div
          v-if="isValidSignUp"
          class="md:max-w-xl"
        >
          <h1 class="text-2xl md:text-3xl mb-2">
            Welcome to Picked’s Assessment Centre
          </h1>
          <p class="text-sm">
            To get started, please enter your information below
            <router-link
              :to="{ name: 'candidate-wizard-sign-in' }"
              class="block text-base"
              href="javascript:;"
            >
              or <span class="cta-link font-medium">sign in to your account</span>
            </router-link>
          </p>

          <div class="mt-8 mb-4">
            <SignInWithGoogle
              @success="signInWithGoogleSuccess"
              @error="signInWithGoogleError"
            />
            <p
              v-if="signInWithGoogleErrorMessage"
              class="text-red-500 text-xs text-left mt-2"
            >
              {{ signInWithGoogleErrorMessage }}
            </p>
          </div>

          <form
            novalidate="novalidate"
            class="mt-4"
            @submit.prevent
          >
            <label class="block mt-6">
              <span class="text-gray-800">First Name</span>
              <input
                v-model="$v.formResponses.firstName.$model"
                class="form-input mt-1 block w-full bg-gray-100"
                autocomplete="given-name"
              >
            </label>
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.formResponses.firstName.required">This field is required</span>
            </ErrorsInline>

            <label class="block mt-6">
              <span class="text-gray-800">Last Name</span>
              <input
                v-model="$v.formResponses.surname.$model"
                class="form-input mt-1 block w-full bg-gray-100"
                autocomplete="family-name"
              >
            </label>
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.formResponses.surname.required">This field is required</span>
            </ErrorsInline>

            <label class="block mt-6">
              <span class="text-gray-800">Email address</span>
              <input
                v-model="$v.formResponses.email.$model"
                type="email"
                class="form-input mt-1 block w-full bg-gray-100"
                autocomplete="email"
              >
            </label>
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.formResponses.email.required">This field is required</span>
              <span v-if="!$v.formResponses.email.email">Needs to be a valid email address</span>
            </ErrorsInline>

            <section class="mt-6">
              <span class="flex">
                <label class="flex items-center">
                  <input
                    v-model="$v.formResponses.terms.$model"
                    type="checkbox"
                    class="form-checkbox"
                  >
                  <span class="ml-2 text-gray-800">
                    I agree to the <a
                      href="/terms/"
                      class="underline"
                      target="_blank"
                    >Terms &amp; Conditions</a>
                  </span>
                </label>
              </span>
              <ErrorsInline v-if="hasErrors">
                <span v-if="!$v.formResponses.terms.sameAs">This field is required</span>
              </ErrorsInline>
            </section>

            <section class="mt-8 text-right">
              <BaseButton
                :loading="loading"
                @click="createCandidate"
              >
                Continue
                <template
                  slot="iconRight"
                >
                  <Icon
                    view-box="0 0 284.929 284.929"
                    class="ml-2 text-white transform rotate-180 w-3 h-3"
                  >
                    <Chevron />
                  </Icon>
                </template>
              </BaseButton>

              <div class="mt-2 relative">
                <ErrorsInline
                  v-for="(error, index) in errors"
                  :key="index"
                  class="right-0"
                >
                  <span
                    v-for="(message, index) in error"
                    :key="index"
                  >{{ message }}</span>
                </ErrorsInline>
              </div>
            </section>
          </form>
        </div>
        <div
          v-else-if="!isValidSignUp"
        >
          <h1 class="text-xl mb-2 font-medium">
            Sorry, the deadline has passed
          </h1>
          <p class="text-lg">
            Get in touch with {{ job.organisationName }} to find out what to do next
          </p>
        </div>
        <div
          v-else
          class="my-12"
        >
          <Loader />
        </div>
      </div>
      <div class="md:w-1/3">
        <div class="bg-tertiary-200 bg-honeycomb text-gray-800 p-4 text-center flex flex-col items-center justify-center rounded-tr-lg rounded-br-lg rounded-bl-lg sm:rounded-br-none h-full w-full">
          <div
            class="xl:w-340 text-center flex flex-col justify-center px-4 xl:px-8"
          >
            <div
              class="w-full z-20 xl:max-w-sm text-center mx-auto xl:relative"
            >
              <h2
                class="inline-flex items-center text-gray-800 leading-tight relative px-2 z-20 bg-tertiary-200 text-center mx-auto text-sm uppercase tracking-wider font-medium"
              >
                <span class="mt-1">Need Help</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </h2>

              <div
                class="flex flex-wrap border border-gray-400 p-6 pt-8 relative rounded"
                style="bottom: 0.75rem;"
              >
                <div class="w-full text-sm text-gray-800 flex">
                  <ul class="flex-1 text-left">
                    <li
                      v-for="helpLink in helpLinks"
                      :key="helpLink.name"
                      class="mb-6 lg:last:mb-0"
                    >
                      <a
                        :href="helpLink.link"
                        target="_blank"
                        class="flex items-center font-medium hover:text-secondary duration-150 ease-out"
                      >
                        <span class="mr-1">{{ helpLink.name }}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 text-gray-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                          <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chevron from '@components/Icons/Chevron'
import SignInWithGoogle from '@components/Auth/SignInWithGoogle'
import ErrorsInline from '@components/ErrorsInline'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Mailbox from '@components/Vectors/Mailbox'

import candidateWizardApi from '@api/candidateWizard'

import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import { setLocalStorage } from '@utils/localStorage'

export default {
  components: {
    Chevron,
    SignInWithGoogle,
    ErrorsInline,
    Icon,
    Loader,
    Mailbox
  },

  mixins: [validationMixin],

  data() {
    return {
      job: null,
      invitation: null,

      loading: false,
      hasErrors: false,
      magicLinkSent: false,
      signInWithGoogleErrorMessage: null,
      errors: [],
      formResponses: {
        firstName: null,
        surname: null,
        email: null,
        terms: false
      },

      helpLinks: [
        {
          link: '/magazine/employee-assessment-tests-how-do-they-work/',
          name: 'How do our assessments work?'
        },
        {
          link: 'https://support.picked.ai/',
          name: 'Support'
        },
        {
          link: '/terms/',
          name: 'Terms & Conditions'
        },
        {
          link: '/privacy-policy/',
          name: 'Privacy Policy'
        },
        {
          link: '/legal/dmca-policy/',
          name: 'DMCA Policy'
        },
        {
          link: '/legal/disability/',
          name: 'Disability Information'
        }
      ]
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    isClean() {
      return !this.$v.formResponses.$anyDirty
    },

    isValidSignUp() {
      if ((this.job && !this.job.deadlineExpired) || !this.job) {
        return true
      }
      return false
    },

    /**
     * @return {String}
     */
    jobId() {
      return this.$route.params.job
    },

    /**
     * @return {String}
     */
    invitationParam() {
      if (!this.$route.query.invitation) {
        return null
      }
      return this.$route.query.invitation
    },

    /**
     * @return {String}
     */
    organisationParam() {
      if (!this.$route.query.organisation) {
        return null
      }
      return this.$route.query.organisation
    }
  },

  validations: {
    formResponses: {
      firstName: {
        required
      },
      surname: {
        required
      },
      email: {
        required,
        email
      },
      terms: {
        sameAs: sameAs(() => true)
      }
    }
  },

  created() {
    this.getJob()
  },

  methods: {
    getJob() {
      if (!this.jobId) {
        return
      }

      return candidateWizardApi.job(this.jobId, this.invitationParam)
        .then(response => {
          this.job = response.data
          this.invitation = response.invitation

          if (this.invitation) {
            this.formResponses = {
              firstName: this.invitation.firstName,
              surname: this.invitation.surname,
              email: this.invitation.email,
              terms: false
            }
          }
        })
        .catch(error => {
          throw error
        })
    },

    createCandidate() {
      if (this.loading || !this.isValidSignUp) {
        return
      }

      this.loading = true
      this.errors = false

      this.$v.formResponses.$touch()
      this.hasErrors = this.$v.formResponses.$anyError
      if (this.hasErrors || this.isClean) {
        this.loading = false
        return
      }

      const candidate = {
        ...this.formResponses,
        jobUuid: this.job ? this.job.uuid : null,
        invitationCode: this.invitationParam,
        organisationId: this.organisationParam
      }

      return candidateWizardApi.create(candidate)
        .then(response => {
          if (response.data.success) {
            this.magicLinkSent = true
            this.loading = false
            return
          }
          this.loading = false
          setLocalStorage('candidateWizard.uuid', response.data.uuid)
          setLocalStorage('candidateWizard.password', response.data.secret)
          this.$router.push({
            name: 'candidate-wizard-dashboard',
            query: {
              job: this.jobId
            }
          })
        })
        .catch(error => {
          this.loading = false
          if (error.response && error.response.data.errors) {
            console.error(error)
            this.errors = error.response.data.errors
            return
          }
          this.errors = ['Sorry, an error occured']
          throw error
        })
    },

    signInWithGoogleSuccess(idToken) {
      this.loading = true
      this.errors = false

      const candidate = {
        idToken: idToken,
        jobUuid: this.job ? this.job.uuid : null,
        organisationId: this.organisationParam,
        invitationCode: this.invitationParam
      }

      return candidateWizardApi.googleSignUp(candidate)
        .then(response => {
          this.loading = false
          setLocalStorage('candidateWizard.uuid', response.data.uuid)
          setLocalStorage('candidateWizard.password', response.data.secret)
          this.$router.push({
            name: 'candidate-wizard-dashboard',
            query: {
              job: this.jobId
            }
          })
        })
        .catch(error => {
          this.loading = false
          if (error.response && error.response.data.errors) {
            console.error(error)
            this.errors = error.response.data.errors
            return
          }
          throw error
        })
    },

    /**
     * Handle error from Google Sign In
     *
     * @param {Object}
     */
    signInWithGoogleError(error) {
      if (error.error === 'popup_closed_by_user') {
        return
      }
      this.signInWithGoogleErrorMessage = error.error
    }
  }
}
</script>

<template>
  <div class="bg-gray-100 flex-grow">
    <div class="w-full max-w-screen-xl relative mx-auto py-8 sm:py-16 px-4 rounded-lg">
      <CandidateWizard />
    </div>
  </div>
</template>

<script>
import CandidateWizard from '@components/CandidateWizard/CandidateWizard'

export default {
  components: {
    CandidateWizard
  }
}
</script>
